<template>
	<div>
		<div class="header">
			<div class="menubar">

				<img v-if="$store.state.config.leftlogo" :src="$store.state.config.leftlogo" alt="">
			</div>
			<div class="headright">
				<div class="moudelname">数据经营平台</div>
				<div style=" float: right; margin-right:20px ">

					<el-dropdown @command="handleCommand" trigger="click">
						<div style="width: 230px; height: 30px;  position: relative; margin-top: 17px;">
							<div class="nickname">
								<div style="cursor: pointer; border: 0; outline: none; color: #fff;">
									欢迎您：{{ userName }}
								</div>
							</div>
							<div class="headimg">
								<img :src="avatar" alt="">
							</div>
						</div>
						<el-dropdown-menu slot="dropdown">
							<!-- <el-dropdown-item command="changeYear" divided>切换年份</el-dropdown-item> -->
							<el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>



				</div>

				<!-- <div
					style="margin-right:300px ; margin-left: 150px; text-align: center; min-width: 300px; overflow: hidden;">
					<div class="bigmenu">
						<div :class="[
							'bmitem bgBlue flexCenter',
							nowbMenu == item.id ? 'bmckd' : '',
						]" v-for="(item, idx) in Menus" :key="idx" @click="goBMenu(item)">
							<i :class="item.icon"></i> {{ item.title }}
							<div class="ckdline">

							</div>
						</div>
					</div>
				</div> -->
			</div>

		</div>
		<div class="menuline"></div>



		<div :class="['leftmenu']">
			<div style="width: 160px; margin: 0 auto; height: 100%;">
				<div style="height: 56px;"></div>

				<div class="leftcollapse" style="height:100%;overflow: auto;">

					<el-collapse v-model="nowsMenu" accordion @change="handleChange">
						<el-collapse-item :title="lm.title" :name="lm.id" v-for="(lm, idx) in Smenus" :key="idx">
							<div slot="title"><span :class="nowsMenu == lm.id ? 'bmenuckd' : ''"><i
										:class="lm.icon"></i> {{
												lm.title
										}}</span></div>
							<div>
								<div :class="['menuitem', nowsMenu2 == item.id ? 'menuckd' : '']"
									v-for="(item, idx) in lm.children" :key="idx" @click="goPage(item)">
									<i :class="item.icon"></i> {{ item.title }}
									<div class="ckdtail" v-if="nowsMenu2 == item.id">

									</div>
								</div>
							</div>

						</el-collapse-item>

					</el-collapse>
					<div style="height: 106px;"></div>

				</div>


			</div>
		</div>




		<el-dialog title="修改密码" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">
			<el-form :model="pwdForm">
				<el-form-item>
					<el-input placeholder="请输入原密码" v-model="pwdForm.oldPwd" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">原密码</span></template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">新密码</span></template>
					</el-input>
				</el-form-item>

				<el-form-item>
					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">确认密码</span></template>
					</el-input>
				</el-form-item>

				<div style="height: 20px;text-align: center;padding-top: 30px;padding-bottom: 20px;">
					<el-button type="primary" style="width: 100%" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>

		<el-dialog title="切换年份" :visible.sync="isShowYearModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">

			<div>
				<el-form :model="pwdForm" inline="inline">

					<el-form-item label="年份">

						<el-select v-model="year">
							<el-option :label="y" :value="y" v-for="(y, i) in yearList" :key="i"></el-option>

						</el-select>

					</el-form-item>

				</el-form>



			</div>

			<div style="height: 20px; text-align: center; padding-top: 30px; padding-bottom: 20px;">
				<el-button type="primary" style="width: 100%;" @click="setYear">确认切换</el-button>
			</div>



		</el-dialog>


	</div>
</template>

<script>
import aes from "../../utils/aes.js";

export default {
	data() {
		return {
			leftLogo: "",
			avatar: "",
			Menus: [],
			Smenus: [],
			nowbMenu: 0,
			nowsMenu: "",
			nowsMenu2: "",
			userName: "",
			isShowModal: false,
			year: "",
			yearList: [],
			isShowYearModal: false,
			ltmenuHide: true,
			pwdForm: {
				oldPwd: "",
				newPwd1: "",
				newPwd2: "",
			},
		};
	},
	mounted() {
		this.getRoleMenu();

		let yearList = [];
		for (let i = new Date().getFullYear(); i >= 2021; i--) {
			yearList.push(i)
		}
		this.yearList = yearList;
		if (sessionStorage.getItem("year")) {
			this.year = sessionStorage.getItem("year")
		}


	},
	methods: {

		getRoleMenu() {

			this.$http.post("/api/getUserMenu").then(res => {
				this.Menus = res.data
				//this.getConfig();
				this.getTeacherInfo(() => {
					for (let item of this.Menus) {

						if (item.path && this.$route.path.indexOf(item.path) >= 0) {
							this.nowsMenu = 0
							this.nowbMenu = item.id


						} else {
							for (let sitem of item.children) {
								console.log(sitem.path, this.$route.path)
								if (sitem.path && this.$route.path.indexOf(sitem.path) >= 0) {
									this.nowsMenu = 0
									this.nowbMenu = item.id
									this.Smenus = item.children;
									console.log("初始化第二级")

								} else {
									for (let sitem2 of sitem.children) {
										if (sitem2.path && this.$route.path.indexOf(sitem2.path) >= 0) {
											this.nowsMenu = sitem.id
											this.nowbMenu = item.id
											this.nowsMenu2 = sitem2.id;
											this.Smenus = item.children;

										} else {


										}
									}

								}
							}
						}
					}




				});

			})
		},
		goBMenu(item) {
			this.Smenus = item.children;
			this.nowbMenu = item.id;
			if (this.Smenus && this.Smenus.length > 0) {

				//this.goPage(this.Smenus[0]);


				if (this.Smenus[0].children && this.Smenus[0].children.length > 0) {
					this.nowsMenu = this.Smenus[0].id;
					this.nowsMenu2 = this.Smenus[0].children[0].id;
					this.$router.push(this.Smenus[0].children[0].path);
				} else {
					this.$router.push(this.Smenus[0].path);
				}

			} else if (item.path) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path);
				}


			}
		},
		handleChange() {
			for (let item of this.Smenus) {
				if (item.id == this.nowsMenu) {
					if (!item.children || item.children.length == 0) {
						this.nowsMenu = []
						this.nowsMenu2 = 0
						if (this.$route.path != item.path) {
							this.$router.push(item.path);
						}
						this.$nextTick(() => {
							this.nowsMenu = []
						})
					}
				}
			}
			console.log(this.nowsMenu)
		},
		goPage(item) {
			if (this.$route.path != item.path) {
				this.$router.push(item.path)
				this.nowsMenu2 = item.id
			}
		},
		setYear() {
			sessionStorage.setItem("year", this.year);
			window.location.reload();
		},
		handleCommand(e) {
			console.log(e);
			if (e == "updPwd") {
				this.isShowModal = true;
			} else if (e == "logout") {
				localStorage.clear();
				sessionStorage.clear();
				this.$router.push("/login");
			} else if (e == "changeYear") {
				this.isShowYearModal = true;
			}
		},
		savePwd() {
			if (!this.pwdForm.oldPwd) {
				this.$message.error("请输入原密码");
				return;
			}

			if (!this.pwdForm.newPwd1) {
				this.$message.error("请输入新密码");
				return;
			}
			if (!this.pwdForm.newPwd2) {
				this.$message.error("请再次输入新密码");
				return;
			}
			if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
				this.$message.error("两次密码不一致");
				return;
			}
			this.$http.post("/api/reset_myself_pwd", this.pwdForm).then((ok) => {
				if (ok.data.code) {
					if (ok.data.code == 500) {
						this.$message.error(ok.data.msg);
					} else {
						this.$message.error("保存失败");
					}
				} else {
					this.$message.success("修改成功");

					this.isShowModal = false;
					this.$router.push("/login");
				}
			});
		},
		getConfig() {
			// this.$http.post("/api/sch_configs").then(res => {
			// 	for (let k of res.data) {

			// 		if (k.ktype == 'leftlogo') {
			// 			this.leftLogo = k.kvalue
			// 		}
			// 	}


			// })
		},
		getTeacherInfo(callback) {

			this.$http.post("/api/get_teacher_info").then(res => {
				this.avatar = res.data.avatar ? res.data.avatar : './img/temp.png'
				this.userName = res.data.username

				if (callback) {
					callback()
				}
			})
		},

	},
};
</script>
<style scoped lang="less" type="text/less">
.moudelname {
	float: left;
	margin-top: 12px;
	margin-left: 20px;
	color: #fff;
	border-left: 4px solid #ffc501;
	color: #ffc501;
	padding-left: 12px;
	font-size: 18px;
	font-weight: bolder;
}


.leftmenu {
	width: 200px;
	height: 100%;
	position: absolute;

	transition: all .3s;
	transform-origin: left;


	.menuitem {
		padding: 10px;
		text-align: left;

		cursor: pointer;
		margin: 0 auto;

		font-size: 14px;
		color: #979797;
		font-weight: bolder;
		position: relative;

		.ckdtail {
			width: 6px;
			height: 12px;
			position: absolute;
			top: 16px;
			right: 0px;
			background-image: url(/img/mleft.png);
			background-size: auto 98%;

			background-repeat: no-repeat;



		}
	}

	// .menuitem:first-child {
	//   border-top-left-radius: 4px;
	//   border-top-right-radius: 4px;
	// }

	// .menuitem:last-child {
	//   border-bottom: 1px solid #0794b3;
	//   border-bottom-left-radius: 4px;
	//   border-bottom-right-radius: 4px;
	//   border-radius: 4px;
	// }

	.menuckd {
		color: #478af3;
	}

	.bmenuckd {
		color: #478af3;
		font-weight: bolder;
	}
}

.menuline {
	height: 2px;
	border: 2px solid #b9cbdf;
	position: absolute;
	top: 50px;
	width: 100%;
	background-color: #e1e9f9;
}

.hidemenu {
	transform: scale(0, 1);
}

.smenubox {
	width: 160px;
	padding-left: 20px;
	padding-top: 30px;
	margin: 0 auto;
	background-color: #fff;
	height: 95%;
	box-shadow: 2px 2px 6px 2px rgba(24, 144, 255, 0.1);
}

.hidemenu {
	transform: scale(0, 1);
}

.menuitem {
	padding: 10px;
	text-align: left;
	cursor: pointer;
	margin: 0 auto;

	color: #333333;
	position: relative;

}

.menuckd {

	color: #1890ff;
	// box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
	border-radius: 4px;
	// border-bottom-left-radius: 20px;
	// transform: translateX(10px);
	position: relative;
	background-color: #e5f0fa;
}

.menuckd .ckdtail {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0px;
	right: 0px;
	background-image: url(/img/mleft.png);
	background-size: auto 98%;

	background-repeat: no-repeat;



}

.header {
	height: 50px;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 1;
	background-color: #2469f3;

	.menubar {
		width: 200px;
		height: 100%;
		float: left;
		color: #fff;
		text-align: center;
		line-height: 50px;

		img {
			width: 100%;
			margin-top: 0px;
		}
	}

	.headright {
		height: 100%;
		margin-left: 200px;

		position: relative;
		text-align: center;

		.bigmenu {

			height: 40px;
			padding-top: 12px;
			color: #fff;

			.bmitem {
				height: 100%;
				padding: 3px 40px 0px 40px;
				border-radius: 4px;
				cursor: pointer;
				display: inline-block;
				vertical-align: top;
				font-weight: bolder;
				margin-right: 10px;
				font-size: 15px;

			}

			.bmckd {


				background-size: 100% 100%;
				font-size: 18px;
				color: #fff;
				transform: translateY(-2px);
				font-weight: bolder;

				.ckdline {
					height: 4px;
					background-color: #ffcb29;
					transform: translateY(5px);
					border-radius: 2.5px;
				}
			}

		}

		.nickname {
			position: absolute;
			right: 60px;
			//top: 10px;
			height: 100%;
			line-height: 100%;
			color: #fff;
			padding: 0 20px;
			border-radius: 4px;
		}

		.headimg {
			width: 30px;
			height: 30px;
			position: absolute;
			top: -8px;
			right: 20px;
			border: 1px solid #0794b3;
			border-radius: 50%;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				cursor: pointer;

			}
		}
	}
}
</style>
