<template>
	<div class="loginbg">

		<div class="login_box">
			<div style="text-align: center; padding-bottom: 20px; font-size: 24px; font-weight: bold;">欢迎登陆中博致信</div>
			<div class="coricon" @click="changeLoginType">
				<img v-if="!showQrcode" :src="'./images/icon-qrcode.png'" alt="">
				<img v-else :src="'./images/icon-pwd.png'" alt="">
			</div>
			<div v-if="!showQrcode">


				<el-form :model="EditItem">

					<el-form-item>

						<el-input placeholder="请输入账号" @keyup.enter.native="login" v-model="username">
							<template slot="prepend">账 号</template>
						</el-input>

					</el-form-item>
					<el-form-item>

						<el-input type="password" placeholder="请输入密码" @keyup.enter.native="login" v-model="password">
							<template slot="prepend">密 码</template>
						</el-input>

					</el-form-item>
					<el-form-item>

						<div style="width: 60%; float: left;">
							<el-input type="text" placeholder="请输入验证码" @keyup.enter.native="login" v-model="yzm">

							</el-input>
						</div>
						<div style="margin-left: 60%; height: 40px; overflow: hidden;" @click="changeYzm">
							<div style="border: 0; width: 150px; height: 50px; transform: scale(0.8,0.8); transform-origin: left top;"
								v-html="yzmUrl">
							</div>

						</div>


					</el-form-item>

					<div style="height: 20px; text-align: center; padding-top: 50px;">
						<el-button type="primary" style="width: 100%;" @click="login">登录</el-button>
					</div>
				</el-form>

			</div>

			<div v-else>
				<div style="width: 60%; margin: 0 auto; padding-top: 30px;">
					<img :src="qrcodeUrl ? qrcodeUrl : '../../images/qrcode.jpg'" alt="" style="width: 100%;">
				</div>

			</div>



		</div>

		


	</div>
</template>

<script>
export default {
	data() {
		return {
			isShowModal: false,
			showbtn: false,
			showVideo: true,
			bgidx: "",
			bgvideourl: "",
			EditItem: {},
			username: "",
			password: "",
			session_id: "",
			yzmUrl: "",
			yzm: "",
			showQrcode: false,
			qrcodeUrl: "",
			qrcodeChecking: false,
			qrcodeTimer: -1,
			pwdForm: {
				newPwd1: "",
				newPwd2: "",

			}
		}
	},
	mounted() {



		this.getYZM()

	},
	methods: {
	
		getQrcode() {
			this.$http.post("/api/login_qrcode", {
				pmid: window.pmid
			}).then(res => {

				this.session_id = res.data.session;

				this.qrcodeUrl = res.data.url;
				if (!this.qrcodeChecking) {
					this.qrcodeChecking = true

					this.checkQrcode();
				}
			})



		},
		checkQrcode() {

			this.qrcodeTimer = setInterval(() => {

				this.$http.post("/api/check_qrcode", {
					session_id: this.session_id,
					noloading: 1,
					pmid: window.pmid
				}).then(res => {
					if (res.data.token) {
						if (res.data.pmid != window.pmid) {
							this.$message({
								message: "错误的二维码",
								type: 'error',
								offset: '600'
							});
						} else {
							let menus = res.data.menus;
							delete res.data.menus
							sessionStorage.setItem("userinfo", JSON.stringify(res.data))
							if (!localStorage.getItem("year")) {
								let month = new Date().getMonth() + 1
								if (month == 12) {
									localStorage.setItem("year", new Date().getFullYear() + 1)
								} else {
									localStorage.setItem("year", new Date().getFullYear())
								}

							}
							sessionStorage.setItem("menus", menus)
							this.getMenus()
							clearInterval(this.qrcodeTimer)
							this.$router.push("/home")
						}

					}
				})
			}, 1000)
		},
		getYZM() {
			this.$http.post("/api/get_login_randomcode", {
				pmid: window.pmid
			}).then(res => {
				this.session_id = res.data.data.session_id;
				this.yzmUrl = res.data.data.svg;
			})


		},
		changeYzm() {
			this.getYZM()
		},
		login() {
			if (this.username.trim() && this.password.trim() ) {//&& this.yzm.trim()
				this.$http.post("/api/admin_login", {
					loginname: this.username,
					mm: this.password,
					yzm: this.yzm.toLowerCase(),
					session_id: this.session_id,
					pmid: window.pmid,
					day:7//7天后过期
				}).then(res => {
					if (res.data && res.data.auth) {
						
						localStorage.setItem("auth", res.data.auth)
						clearInterval(this.qrcodeTimer)
						this.$router.push("/home")
						// if (!res.data.upd_pwd) {
						// 	this.isShowModal = true
						// } else {
						// 	this.$router.push("/home")
						// }

					} else {
						this.$message({
							message: res.data.msg,
							type: 'error',
							offset: '600'
						});

					}


				}, err => {

				})
			} else {
				this.$message({
					message: "好像没有填完整~",
					type: 'error',
					offset: '600'
				});
			}
		},
		changeLoginType() {
			this.showQrcode = !this.showQrcode
			if (this.showQrcode && !this.qrcodeUrl) {
				this.getQrcode()
			}
		},

		checkPassWord(value) {
			if (value.length < 8 || value.length > 16) { //最初级别
				this.$message.error("密码长度为8-16位")
				return false;
			}
			if (!/\d/.test(value)) { //如果用户输入的密码 包含了数字
				this.$message.error("密码必须包含数字")
				return false;
			}
			if (!/[a-z]/.test(value) && !/[A-Z]/.test(value)) { //如果用户输入的密码 包含了小写的a到z
				this.$message.error("密码必须包含大小写字母")
				return false;
			}

			var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
				regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

			if (!regEn.test(value) && !regCn.test(value)) {
				this.$message.error("密码必须包含特殊字符")
				return false;
			}

			return true


		},

		savePwd() {

			if (!this.pwdForm.newPwd1) {
				this.$message.error("请输入新密码")
				return
			}
			if (!this.pwdForm.newPwd2) {
				this.$message.error("请再次输入新密码")
				return
			}
			if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
				this.$message.error("两次密码不一致")
				return
			}
			if (this.checkPassWord(this.pwdForm.newPwd2)) {
				this.showbtn = true;
				this.$http.post("/api/reset_myself_pwd_first", this.pwdForm).then(ok => {
					if (ok.data) {
						this.$message.success("修改成功,即将跳转")
						setTimeout(() => {
							this.$router.push("/home")
						}, 3000)

					}
				})
			}


		},
	}
}
</script>

<style scoped>
.loginbg {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-image: url(http://desk-fd.zol-img.com.cn/t_s1920x1080/g2/M00/0A/0F/ChMlWl7N03WIIL59ABIypJVaTEMAAPg7wJlY6oAEjK8712.jpg);
}

.login_box {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -180px;
	margin-top: -270px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	background-color: #fff;
	width: 320px;
	height: 340px;
	padding: 20px;
	box-shadow: 0 2px 10px #999;
	-moz-box-shadow: #999 0 2px 10px;
	-webkit-box-shadow: #999 0 2px 10px;
}

.coricon {
	position: absolute;
	top: 0;
	right: 0;
	;
	width: 60px;
	height: 60px;
}

.coricon img {
	width: 100%;
	transform: scale(0.5, 0.5);
}

.bgvideo {
	position: fixed;
	width: 100%;
	height: 100%;
	top: -10;
	left: 0;
	z-index: -100;

}

.bgvideo video {
	width: 100%;
	/*加滤镜*/
	filter: blur(0px);

}
</style>
