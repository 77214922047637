let  cryptoJS = require( 'crypto-js' );
let generatekey = () => {
	let a = "xzzbzx";
	let key = a + ".aiwx.org.cn"
	return key;
};
export default {
	//随机生成指定数量的16进制key


	
	//加密
	encrypt(word) {
		let keyStr = generatekey(); //判断是否存在ksy，不存在就用定义好的key
		let encJson = cryptoJS.AES.encrypt(word, keyStr).toString();
		//对加密数据进行base64处理, 原理：就是先将字符串转换为utf8字符数组，再转换为base64数据
		let encData = cryptoJS.enc.Base64.stringify(cryptoJS.enc.Utf8.parse(encJson));
		return encData
	},
	//解密
	decrypt(word) {
		let keyStr = generatekey();
		let decData = cryptoJS.enc.Base64.parse(word).toString(cryptoJS.enc.Utf8);
		//解密数据
		let decJson = cryptoJS.AES.decrypt(decData, keyStr).toString(cryptoJS.enc.Utf8);
		return decJson
	}

}
