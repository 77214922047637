import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import index from '../views/index.vue'
import h5 from '../views/h5/index.vue'
Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '*',
		redirect: "/home"
	},
	{
		path: '/h5',
		name: 'h5',
		component: h5,
		children: [{
			path: '/welcome',
			component: resolve => require(['../views/h5/welcome'], resolve)
		},{
			path: '/yw_data',
			component: resolve => require(['../views/h5/yw_data'], resolve)
		},{
			path: '/zt_data',
			component: resolve => require(['../views/h5/zt_data'], resolve)
		},{
			path: '/zhengce',
			component: resolve => require(['../views/h5/zhengce'], resolve)
		},{
			path: '/chengben',
			component: resolve => require(['../views/h5/chengben'], resolve)
		}]
	},
	{
		path: '/index',
		name: 'index',
		component: index,
		children: [

			{
				path: '/home',
				component: resolve => require(['../views/home/home'], resolve)
			},
			{
				path: '/sys_menu',
				component: resolve => require(['../views/sys/sys_menu'], resolve)
			}, {
				path: '/sys_role',
				component: resolve => require(['../views/sys/sys_role'], resolve)
			},
			{
				path: '/sys_teacherDept',
				component: resolve => require(['../views/sys/sys_teacherDept'], resolve)
			},
			{
				path: '/init',
				component: resolve => require(['../views/sys/theme'], resolve)
			},
			// {
			// 	path: '/sys_deptRole',
			// 	component: resolve => require(['../views/sys/sys_deptRole'], resolve)
			// }, {
			// 	path: '/sys_deptUser',
			// 	component: resolve => require(['../views/sys/sys_deptUser'], resolve)
			// },
			{
				path: '/users',
				component: resolve => require(['../views/sys/teacher'], resolve)
			},



			{
				path: '/directory',
				component: resolve => require(['../views/sys/directory'], resolve)
			},
			{
				path: '/zhuti',
				component: resolve => require(['../views/sys/zhuti'], resolve)
			},

			{
				path: '/sys_modules',
				component: resolve => require(['../views/sys/sys_module'], resolve)
			},

			{
				path: '/notify',
				component: resolve => require(['../views/notify/list'], resolve)
			}, {
				path: '/notifyedit',
				component: resolve => require(['../views/notify/articleedit'], resolve)
			}, {
				path: '/banner',
				component: resolve => require(['../views/sys/banner.vue'], resolve)
			},{
				path: '/kaipiao',
				component: resolve => require(['../views/kaipiao/kplist.vue'], resolve)
			},{
				path: '/kp_process',
				component: resolve => require(['../views/kaipiao/kpprocess.vue'], resolve)
			},{
				path: '/dz_process',
				component: resolve => require(['../views/kaipiao/dzprocess.vue'], resolve)
			},{
				path: '/yeargole',
				component: resolve => require(['../views/sys/yeargole.vue'], resolve)
			},


		]
	},


]



const router = new VueRouter({
	mode:"history",
	base: process.env.BASE_URL,
	routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function (name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}
	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}


//路由进入之前检查是否登录
router.beforeEach((to, from, next) => {
	let auth = $request("auth"); //检查url是否带有登录字符串
	if (auth) {
		window.localStorage.setItem("auth", auth); //将登录字符串保存到缓存
		//移除url的auth并刷新页面
		let localUrl = window.location.href.replace(auth, '').replace("?auth=", '').replace("&auth=", '')
		window.location.href = localUrl
		setTimeout(()=>{
			window.location.reload()
		},200)
	} else { //跳转统一登录
		if (window.localStorage.getItem("auth")) { //检查本地是否登录
			next()
		} else {
			if (to.path == "/login") {
				next()
			} else {
				next({ path: "/login" })
			}
		}
	}
})



export default router
